import React, { FunctionComponent, useState, useContext, useCallback } from "react";
import { withAuthentication } from "../../services/authentication/authentication.provider";
import { WithTranslations, HasContainers } from "../../services/localisation/types";

import { PageContainer } from "../Atoms/PageContainer";
import { ShortArrowIcon, ArrowIcon } from "../Icons";
import { addTranslations } from "../../services/localisation/utils";
import styles from "./navigation.module.scss";
import { PlainButton } from "../Button/button";
import { LanguageSelection } from "../../containers/language-selection";
import { Link } from "../Atoms/Link";
import { NavBar } from "../NavBar";
import { OrderingUsersOnly } from "../Controls/UserControl/ordering-users-only";
import { useIsDesktop } from "../../utils/use-is-desktop";
import { useUserInformation } from "../../utils/use-user-information";

const NO_OP = () => {};
const defaultState: NavigationContext = {
  open: false,
  showNavigation: NO_OP,
  hideNavigation: NO_OP
};

interface NavigationContext {
  open: boolean;
  showNavigation: () => void;
  hideNavigation: () => void;
}

export const NavigationStateContext = React.createContext(defaultState);

export const NavigationStateProvider: FunctionComponent = ({ children }) => {
  const [open, setOpen] = useState(defaultState.open);
  const showNavigation = () => setOpen(true);
  const hideNavigation = useCallback(() => setOpen(false), [setOpen]);

  return (
    <NavigationStateContext.Provider
      value={{
        open,
        showNavigation,
        hideNavigation
      }}
    >
      {children}
    </NavigationStateContext.Provider>
  );
};

type NavigationPage = "MAIN" | "LANGUAGE";

const defaultTranslations = {
  close: "Close",
  language: "Language",
  logOut: "Log out",
  changePassword: "Change password",
  admin: "User management",
  home: "Home page",
  orders: "Orders",
  shunting: "Shunting",
  reports: "Reports",
  releaseNotes: "Release notes",
  cookies: "Cookies",
  inventoryAndLoadingInstructions: "Inventory and loading instructions",
  instructionsOfUse: "Instructions of use"
};

const UserEmail = withAuthentication(({ auth }) => (
  <p className={styles.email}>{auth.userAttributes && auth.userAttributes["email"]}</p>
));

const defaultLanguageSelectionTranslations = {
  language: "Language"
};

export const UntranslatedLanguagePage: FunctionComponent<
  WithTranslations<
    {
      toMainNavigation: () => void;
      onLanguageChange: () => void;
    },
    typeof defaultLanguageSelectionTranslations
  >
> = ({ translations = defaultLanguageSelectionTranslations, ...props }) => {
  return (
    <>
      <div className={styles.centerBackLink}>
        <PlainButton className={styles.backLink} onClick={() => props.toMainNavigation()}>
          <ArrowIcon className={styles.backArrow} direction="left" />
        </PlainButton>
      </div>
      <div className={styles.titleContainer}>
        <p className={styles.title}>{translations.language}</p>
      </div>
      <div className={styles.languageSelection}>
        <LanguageSelection onChange={() => props.onLanguageChange()} />
      </div>
    </>
  );
};

export const LanguagePage = addTranslations(UntranslatedLanguagePage, {
  language: { id: "options.language" }
});

export const UntranslatedOptionsPage: FunctionComponent<
  WithTranslations<
    {
      hideNavigation: () => void;
      showLanguageSelection: () => void;
      logOut: () => void;
    },
    typeof defaultTranslations
  > &
    HasContainers
> = withAuthentication(({ translations = defaultTranslations, ...props }) => {
  const isSignedIn = Boolean(props.auth.userInformation);

  const {
    reports = [],
    isAdUser,
    isAdminInSubuserGroup,
    isAdminInUserGroup,
    customers,
    isGlobalAdmin,
    userGroups,
    subuserGroups,
    visit
  } = useUserInformation();

  const hasRailCustomers = customers?.find(c => c.systemId === "rail");

  const isDesktop = useIsDesktop();

  const shouldSeeManagementLink = Boolean(isAdminInUserGroup || isAdminInSubuserGroup || isAdUser);

  const shuntingAllowed =
    isGlobalAdmin ||
    userGroups?.some(g => {
      return g.allowOrderShunting;
    }) ||
    isAdUser ||
    subuserGroups?.some(g => {
      return g.allowOrderShunting;
    }) ||
    visit?.allowOrderShunting;

  return (
    <>
      <div className={styles.emailContainer}>
        <UserEmail />
      </div>
      <ul className={styles.list}>
        {isSignedIn && !isDesktop && (
          <li>
            <Link onClick={() => props.hideNavigation()} className={styles.listItem} to={`/`}>
              <p className={styles.listItemText}>{translations.home}</p>
              <ShortArrowIcon className={styles.arrowIcon} />
            </Link>
          </li>
        )}

        {shouldSeeManagementLink && !isDesktop && (
          <li>
            <Link onClick={() => props.hideNavigation()} className={styles.listItem} to={`/admin/user-groups`}>
              <p className={styles.listItemText}>{translations.admin}</p>
              <ShortArrowIcon className={styles.arrowIcon} />
            </Link>
          </li>
        )}

        {isSignedIn && !isDesktop && (
          <OrderingUsersOnly>
            <li>
              <Link onClick={() => props.hideNavigation()} className={styles.listItem} to={`/orders`}>
                <p className={styles.listItemText}>{translations.orders}</p>
                <ShortArrowIcon className={styles.arrowIcon} />
              </Link>
            </li>
          </OrderingUsersOnly>
        )}

        {isSignedIn && !isDesktop && shuntingAllowed && (
          <li>
            <Link onClick={() => props.hideNavigation()} className={styles.listItem} to={`/shunting`}>
              <p className={styles.listItemText}>{translations.shunting}</p>
              <ShortArrowIcon className={styles.arrowIcon} />
            </Link>
          </li>
        )}

        {isSignedIn && !isDesktop && reports.length > 0 && (
          <li>
            <Link onClick={() => props.hideNavigation()} className={styles.listItem} to={`/reports`}>
              <p className={styles.listItemText}>{translations.reports}</p>
              <ShortArrowIcon className={styles.arrowIcon} />
            </Link>
          </li>
        )}

        {isSignedIn && hasRailCustomers && (
          <li>
            <Link className={styles.listItem} to={`/inventory-and-loading-instructions/inventory-and-loading-instructions`}>
              <p className={styles.listItemText}>{translations.inventoryAndLoadingInstructions}</p>
              <ShortArrowIcon className={styles.arrowIcon} />
            </Link>
          </li>
        )}

        {isSignedIn && (
          <li>
            <Link className={styles.listItem} to={`/instructions/general`}>
              <p className={styles.listItemText}>{translations.instructionsOfUse}</p>
              <ShortArrowIcon className={styles.arrowIcon} />
            </Link>
          </li>
        )}

        {isSignedIn && (
          <li>
            <Link onClick={() => props.hideNavigation()} className={styles.listItem} to={`/options/release-notes`}>
              <p className={styles.listItemText}>{translations.releaseNotes}</p>
              <ShortArrowIcon className={styles.arrowIcon} />
            </Link>
          </li>
        )}

        <li>
          <Link className={styles.listItem} to={`/policies/cookie-policy`}>
            <p className={styles.listItemText}>{translations.cookies}</p>
            <ShortArrowIcon className={styles.arrowIcon} />
          </Link>
        </li>

        {isSignedIn && !isAdUser && (
          <li>
            <Link className={styles.listItem} to={`/options/change-password`}>
              <p className={styles.listItemText}>{translations.changePassword}</p>
            </Link>
          </li>
        )}

        <li>
          <PlainButton className={styles.listItem} onClick={() => props.showLanguageSelection()}>
            <p className={styles.listItemText}>{translations.language}</p>
            <ShortArrowIcon className={styles.arrowIcon} />
          </PlainButton>
        </li>

        {/* {isSignedIn && ( */}
        <li>
          <PlainButton className={styles.listItem} onClick={() => props.logOut()}>
            <p className={styles.listItemText}>{translations.logOut}</p>
          </PlainButton>
        </li>
        {/* )} */}
      </ul>
    </>
  );
});

export const OptionsPage = addTranslations(UntranslatedOptionsPage, {
  close: { id: "options.close" },
  language: { id: "options.language" },
  logOut: { id: "navbar.logout" },
  changePassword: { id: "navbar.changePassword" },
  admin: { id: "options.admin" },
  home: { id: "options.home" },
  orders: { id: "navigation.orders" },
  shunting: { id: "navigation.shunting" },
  reports: { id: "navigation.reports" },
  releaseNotes: { id: "options.releaseNotes" },
  cookies: { id: "policy.cookies" },
  inventoryAndLoadingInstructions: { id: "options.inventoryAndLoadingInstructions" },
  instructionsOfUse: { id: "options.instructionsOfUse" }

});

export const Navigation: FunctionComponent<{
  logOut: () => void;
}> = props => {
  const [page, setPage] = useState<NavigationPage>("MAIN");
  const { hideNavigation } = useContext(NavigationStateContext);

  return (
    <>
      <NavBar navigationStyle="MAIN" />
      <PageContainer className={styles.pageContainer}>
        {page === "MAIN" && (
          <OptionsPage
            showLanguageSelection={() => setPage("LANGUAGE")}
            hideNavigation={hideNavigation}
            logOut={() => {
              props.logOut();
              hideNavigation();
            }}
          />
        )}

        {page === "LANGUAGE" && (
          <LanguagePage
            onLanguageChange={() => {
              hideNavigation();
            }}
            toMainNavigation={() => setPage("MAIN")}
          />
        )}
      </PageContainer>
    </>
  );
};
