import { FunctionComponent, createContext, useState, useContext } from "react";
import classnames from "classnames";
import { WithTranslations, HasContainers } from "../../../services/localisation/types";
import { addTranslations } from "../../../services/localisation/utils";
import { ExclamationMarkIcon, CloseIcon } from "../../Icons";
import { PlainButton } from "../../Button/button";
import { hasCustomers } from "../../Controls/CustomerControl/customer-control";
import { withAuthentication } from "../../../services/authentication/authentication.provider";

import styles from "./status-message.module.scss";

const defaultTranslations = {
  statusMessageRail: "",
  statusMessageRoad: "",
  statusMessageRelease: ""
};

const NO_OP = () => {};
const defaultState: StatusMessageContext = {
  open: true,
  showStatusMessage: NO_OP,
  hideStatusMessage: NO_OP
};

interface StatusMessageContext {
  open: boolean;
  showStatusMessage: () => void;
  hideStatusMessage: () => void;
}

export const StatusMessageStateContext = createContext(defaultState);

export const StatusMessageStateProvider: FunctionComponent = ({ children }) => {
  const [open, setOpen] = useState(defaultState.open);
  const showStatusMessage = () => setOpen(true);
  const hideStatusMessage = () => setOpen(false);

  return (
    <StatusMessageStateContext.Provider
      value={{
        open,
        showStatusMessage,
        hideStatusMessage
      }}
    >
      {children}
    </StatusMessageStateContext.Provider>
  );
};

const UntranslatedStatusMessage: FunctionComponent<
  WithTranslations<{}, typeof defaultTranslations> & HasContainers
> = withAuthentication(({ translations = defaultTranslations, ...props }) => {
  const { open, hideStatusMessage } = useContext(StatusMessageStateContext);

  const showRailMessages = hasCustomers("rail", props.auth.userInformation);
  const showRoadMessages = hasCustomers("road", props.auth.userInformation);

  const railMessage = translations.statusMessageRail !== "undefined" ? translations.statusMessageRail : undefined;
  const roadMessage = translations.statusMessageRoad !== "undefined" ? translations.statusMessageRoad : undefined;
  const releaseMessage = translations.statusMessageRelease !== "undefined" ? translations.statusMessageRelease : undefined;

  const uniquifyStatusMessages = (releaseMessage?: string, railMessage?: string, roadMessage?: string): string[] => {
    const messages: string[] = [];

    if (releaseMessage) {
      messages.push(releaseMessage);
    }

    if (railMessage && showRailMessages) {
      messages.push(railMessage);
    }

    if (roadMessage && showRoadMessages) {
      messages.push(roadMessage);
    }

    return messages.filter((elem, index, all) => all.indexOf(elem) === index);
  };

  const statusMessages = uniquifyStatusMessages(releaseMessage, railMessage, roadMessage);
  const multipleMessages = statusMessages.length > 1;

  return (
    <>
      {statusMessages.length > 0 && open && props.auth.userInformation && (
        <div className={styles.statusMessageContainer}>
          <div className={classnames(styles.icon, { [styles.flexStart]: multipleMessages })}>
            <ExclamationMarkIcon className={styles.icon} />
          </div>
          <div className={styles.statusMessage}>
            {statusMessages.map(statusMessage => (
              <p key={statusMessage} className={styles.singleStatusMessage}>
                {statusMessage}
              </p>
            ))}
          </div>
          <div className={classnames(styles.close, { [styles.flexStart]: multipleMessages })}>
            <PlainButton className={styles.close} onClick={() => hideStatusMessage()}>
              <CloseIcon />
            </PlainButton>
          </div>
        </div>
      )}
    </>
  );
});

export const StatusMessage = addTranslations(UntranslatedStatusMessage, {
  statusMessageRail: { id: "main.statusMessageRail" },
  statusMessageRoad: { id: "main.statusMessageRoad" },
  statusMessageRelease: { id: "main.statusMessageRelease" }
});
